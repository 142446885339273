import 'moment/locale/es';
import moment from 'moment';
moment.locale('es');

export const currencyFormat = (
  value: number,
  showDecimals = false,
  country = 'es-CO',
  currency = 'COP'
): string => {
  const currencyString = new Intl.NumberFormat(country, {
    style: 'currency',
    currency,
  }).format(value);
  return `${showDecimals ? currencyString : currencyString.slice(0, -3)} COP`;
};

/**
 * @dev SUN is duplicated since backend has 7 as SUN number representation
 */
type WeekDaysType = 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';
const weekDays: WeekDaysType[] = [
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT',
  'SUN',
];

type NormalizedSchedulesType = {
  SUN: Array<number[]>;
  MON: Array<number[]>;
  TUE: Array<number[]>;
  WED: Array<number[]>;
  THU: Array<number[]>;
  FRI: Array<number[]>;
  SAT: Array<number[]>;
};

const getUpperTime = (startTime: number, endTime: number) => {
  // Means end time is on the next day
  return startTime > endTime ? endTime + 240000 : endTime;
};

export const spaceOpen = (spaceSchedules: BussinessHours): boolean => {
  const today = new Date();
  // sun: 0, mon: 1, tue: 2, ...
  const day = today.getDay();
  const hour =
    today.getHours() < 10 ? `0${today.getHours()}` : `${today.getHours()}`;
  const minutes =
    today.getMinutes() < 10
      ? `0${today.getMinutes()}`
      : `${today.getMinutes()}`;
  const seconds =
    today.getSeconds() < 10
      ? `0${today.getSeconds()}`
      : `${today.getSeconds()}`;

  const normalizedSchedules: NormalizedSchedulesType = {
    SUN: [],
    MON: [],
    TUE: [],
    WED: [],
    THU: [],
    FRI: [],
    SAT: [],
  };

  spaceSchedules.forEach((sc) => {
    sc.days.forEach((day) => {
      normalizedSchedules[weekDays[day]].push([
        Number(sc.start_time.replace(/:/g, '')),
        getUpperTime(
          Number(sc.start_time.replace(/:/g, '')),
          Number(sc.end_time.replace(/:/g, ''))
        ),
      ]);
    });
  });

  const currentTime = Number(`${hour}${minutes}${seconds}`);
  const currentSchedule = normalizedSchedules[weekDays[day]];
  const isBetween = (value: number, range: number[]) =>
    value >= range[0] && value <= range[1];

  return currentSchedule.some((range: number[]) =>
    isBetween(currentTime, range)
  );
};

export const getOpenTime = (spaceSchedules: BussinessHours): string => {
  const openTime = Number(spaceSchedules[0].start_time.split(':')[0]);
  return openTime > 12 ? `${openTime % 12}:00 PM` : `${openTime}:00 AM`;
};

export const getCloseTime = (spaceSchedules: BussinessHours): string => {
  const closeTime = Number(spaceSchedules[0].end_time.split(':')[0]);
  return closeTime > 12 ? `${closeTime % 12}:00 PM` : `${closeTime}:00 AM`;
};

// TODO: this util just works for events that are gonna be purchased the start date
// TODO: this utils just work for covers, since we are using days instead specific hours
export const getAvailableDates = (events: EventType[]) => {
  const today = moment().startOf('day').utc();
  return events
    .map((e) => ({
      ...e,
      start_moment: moment(e.start_datetime).startOf('day').utc(),
      end_moment: moment(e.end_datetime),
    }))
    .filter(
      (ev) =>
        ev.start_moment.isSameOrAfter(today) ||
        moment().isBetween(ev.start_moment, ev.end_moment)
    )
    .map((ad) => ad.start_moment);
};

export const getDateOptions = (events: EventType[]) => {
  // For small number of events, just show all of them
  if (events.length < 3) {
    return events.map((e) => {
      const date = moment(e.start_datetime).startOf('day').utc().toDate();
      const label = `${date.getDate()}/${
        date.getMonth() + 1
      }/${date.getFullYear()}`;
      return {
        value: date.toUTCString(),
        label,
      };
    });
  }

  const availableDates = getAvailableDates(events);
  const today = moment().startOf('day').utc();
  const tomorrow = moment().add(1, 'day').startOf('day').utc();
  const afterTomorrow = moment().add(2, 'day').startOf('day').utc();
  const eventsWithMoment = events.map((e) => ({
    ...e,
    start_moment: moment(e.start_datetime),
    end_moment: moment(e.end_datetime),
  }));

  const dateOptions: {
    value: Date;
    label: string;
  }[] = [];

  // Enable Now Option
  // TODO: we should change this in the future to support events that are happening at the same time
  const eventsHappeningNow = eventsWithMoment.filter((e) => {
    return moment().isBetween(e.start_moment, e.end_moment);
  });

  if (eventsHappeningNow.length > 0) {
    dateOptions.push({
      value: moment(eventsHappeningNow[0].start_datetime)
        .startOf('day')
        .utc()
        .toDate(),
      label: '¡Ahora!',
    });
  }

  // Enable Today Option
  const enableToday = eventsWithMoment.some((ev) => {
    return (
      ev.start_moment.isBetween(today, moment(today).endOf('day')) &&
      !moment().isBetween(ev.start_moment, ev.end_moment) // is not happening now
    );
  });

  if (enableToday) {
    dateOptions.push({ value: today.toDate(), label: 'Hoy' });
  }

  // Enable Tomorrow Option
  const enableTomorrow = eventsWithMoment.some((ev) => {
    return ev.start_moment.isBetween(tomorrow, moment(tomorrow).endOf('day'));
  });

  if (enableTomorrow) {
    const tomorrowDate = tomorrow.toDate();
    dateOptions.push({
      value: tomorrowDate,
      label:
        tomorrowDate.getDate() === 1
          ? `1/${tomorrowDate.getMonth() + 1}/${tomorrowDate.getFullYear()}`
          : 'Mañana',
    });
  }

  // Enable After Tomorrow Option
  const enableAfterTomorrow = eventsWithMoment.some((ev) => {
    return ev.start_moment.isBetween(
      afterTomorrow,
      moment(afterTomorrow).endOf('day')
    );
  });

  if (enableAfterTomorrow) {
    const afterTomorrowDate = afterTomorrow.toDate();
    dateOptions.push({
      value: afterTomorrowDate,
      label: `${afterTomorrowDate.getDate()}/${
        afterTomorrowDate.getMonth() + 1
      }/${afterTomorrowDate.getFullYear()}`,
    });
  }

  // If we don't have enough options yet, add the next few available dates
  if (dateOptions.length < 2) {
    // Get the next available dates excluding any we've already added
    const existingDates = dateOptions.map((option) =>
      moment(option.value).startOf('day').format('YYYY-MM-DD')
    );

    const nextDates = availableDates
      .filter(
        (date) =>
          !existingDates.includes(date.format('YYYY-MM-DD')) &&
          date.isAfter(moment())
      )
      .slice(0, 3 - dateOptions.length);

    for (const nextDate of nextDates) {
      const date = nextDate.toDate();
      dateOptions.push({
        value: date,
        label: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
      });
    }
  }

  return dateOptions.map(({ value, ...rest }) => ({
    value: value.toUTCString(),
    ...rest,
  }));
};

export const generatePayzenDate = () => {
  const date = new Date();
  const year = date.getUTCFullYear();
  const month =
    date.getUTCMonth() + 1 < 10
      ? `0${date.getUTCMonth() + 1}`
      : date.getUTCMonth() + 1;
  const day =
    date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate();
  const hours =
    date.getUTCHours() < 10 ? `0${date.getUTCHours()}` : date.getUTCHours();
  const minutes =
    date.getUTCMinutes() < 10
      ? `0${date.getUTCMinutes()}`
      : date.getUTCMinutes();
  const seconds =
    date.getUTCSeconds() < 10
      ? `0${date.getUTCSeconds()}`
      : date.getUTCSeconds();
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

export const makeid = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getEventIdFromDate = (
  events: EventType[],
  dateUTCString: string
) => {
  const foundEvent = events.find((e) =>
    moment(e.start_datetime).startOf('day').isSame(moment(dateUTCString))
  );

  return foundEvent?.id as string;
};

export const getEventDateDay = (dateUTCString: string) => {
  return moment(dateUTCString).locale('es').format('dddd D');
};

export const getEventDateDayWithMonth = (dateUTCString: string) => {
  return moment(dateUTCString).locale('es').format('MMMM / dddd D');
};

export const slugToTitle = (slug: string) => {
  return slug
    .split('-')
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ');
};
