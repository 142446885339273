export const EXPLORER_RECOMMENDED_WALLET_IDS = [
  'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', //METAMASK
  'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa', //COINBASE
  'e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4', //EXODUS
  '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369', //RAINBOW
];

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

// Categories slugs to group by
export const CATEGORIES_SLUGS_TO_GROUP_BY = [
  'mas-visitados',
  'calle-85',
  'zona-t',
  'zona-galerias',
  'zona-modelia',
  'zona-cedritos',
  'calle-93',
  'zona-1ro-de-mayo',
  'zona-h',
  'zona-chapinero',
];

export const CITY_TO_READABLE = {
  bogota: 'Bogotá',
  medellin: 'Medellín',
  bucaramanga: 'Bucaramanga',
};

export const SUPPORTED_CITIES = ['bogota', 'medellin', 'bucaramanga'];
export const CITY_TO_SLUG = {
  bogota: '11001-bogota-dc',
  bucaramanga: '68001-bucaramanga',
};

export const CITY_TO_ID = {
  bogota: 'faeea943-a5c1-4c03-8f9c-ca6bb5e6d888',
  bucaramanga: '225791d2-517c-4ff3-a6f5-dc713f0b8e94',
};

export const ID_TO_CITY = {
  'faeea943-a5c1-4c03-8f9c-ca6bb5e6d888': 'bogota',
  '225791d2-517c-4ff3-a6f5-dc713f0b8e94': 'bucaramanga',
};

export const SPACES_SLUGS_TO_EXCLUDE = [
  'mr-col-invitaciongeneral',
  'mr-col-invitadovip',
  'mr-col-invitadoespecial',
  'mr-col-palcoespecial2',
  'mr-col-palcoespecial1',
  'mr-col-palcoaliados',
  'mitosgeneral',
  'emerge2025',
];
