import { Listbox, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { parseCookies, setCookie } from 'nookies';

const availableCities = [
  { label: 'Bogotá', value: 'bogota' },
  { label: 'Bucaramanga', value: 'bucaramanga' },
];

export default function CitySelector() {
  const router = useRouter();
  const cookies = parseCookies();
  const city = cookies.city || 'bogota';
  const [selectedCity, setSelectedCity] = useState(
    availableCities.find((c) => c.value === city) || availableCities[0]
  );
  return (
    <Listbox
      value={selectedCity}
      onChange={(value) => {
        setSelectedCity(value);
        setCookie(null, 'city', value.value, {
          maxAge: 30 * 24 * 60 * 60,
          path: '/',
        });
        router.push(`/${value.value}`);
      }}
    >
      <div className="relative w-36 ml-2">
        <Listbox.Button
          className={
            'relative flex gap-2 items-center w-full cursor-default rounded-lg text-left focus:outline-none focus-visible:ring-2 text-white focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm overflow-visible'
          }
        >
          <span className="block truncate">{selectedCity?.label}</span>
          <span className="pointer-events-none flex items-center pr-2">
            <BsChevronDown className="h-3 w-3 text-white" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 w-auto bg-white rounded-md shadow-lg max-h-32 sm:text-sm ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto right-0">
            {availableCities.map((language, k) => (
              <Listbox.Option
                key={k}
                value={language}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 px-4 ${
                    active
                      ? 'bg-copper-100 text-copper-900'
                      : 'text-gray-900 bg-white'
                  }`
                }
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {language.label}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
