import React, { useCallback } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import SearchInput from './Common/SearchInput';
import { CITY_TO_READABLE } from '@/constants';

type SearchItem = {
  slug: string;
  name: string;
};

type ModalProps = {
  show: boolean;
  onClose: () => void;
  city: string;
};

export default function SearchModal(
  { show, onClose, city }: ModalProps = {
    show: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClose: () => {},
    city: 'bogota',
  }
) {
  const router = useRouter();
  const referral = router.query.referral;
  const language = router.locale as SupportedLanguages;
  const isSpanish = language === 'es';

  //api/spaces/search?name__icontains

  const handleOnSelect = useCallback(
    (item: SearchItem) => {
      if (referral) {
        router.push(`/${city}/${item.slug}?referral=${referral}`);
        return;
      }
      // the item selected
      router.push(`/${city}/${item.slug}`);
      onClose();
    },
    [referral, router]
  );

  return (
    <>
      <Transition appear show={show} as="div">
        <div className="fixed inset-0 z-[60] light bg-white h-full flex flex-col justify-center px-2 text-[#4A4A4A]">
          <button onClick={onClose}>
            <IoMdClose
              className="absolute text-copper top-2 right-2"
              size="2em"
            />
          </button>
          <div className="flex flex-col grow justify-center items-center">
            <h2 className="font-bold text-xl md:text-3xl px-4">
              {isSpanish ? '¡Encuentra los mejores' : 'Find the best'}
            </h2>
            <h3 className="md:text-2xl text-base px-10 pt-0">
              {isSpanish
                ? `lugares de ${CITY_TO_READABLE[city]}!`
                : `places in ${CITY_TO_READABLE[city]}!`}
            </h3>
            <div className="w-80 py-12">
              <SearchInput onSelect={handleOnSelect} city={city} />
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}
