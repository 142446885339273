import React, { useEffect, useLayoutEffect } from 'react';
import { useState } from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import 'firebase/compat/auth';
import { getFirebaseApp } from '../helpers/firebase';
import LoginModal from './LoginModal';
import UserModal from './UserModal';
import { CgMenu } from 'react-icons/cg';
import Image from 'next/image';
import Link from 'next/link';
import LanguageSelect from './LanguageSelect';
import CitySelector from './CitySelector';

const auth = getAuth(getFirebaseApp());

type HeaderProps = {
  float?: boolean;
  hideMenu?: boolean;
  showCitySelector?: boolean;
};

const Header = ({
  float = false,
  hideMenu = false,
  showCitySelector = false,
}: HeaderProps) => {
  const [isClient, setIsClient] = useState(false);

  const [user] = useAuthState(auth);

  const router = useRouter();
  const [open, setOpen] = useState(false);

  const handleMenuClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setIsClient(true);
  }, []);

  useLayoutEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [open]);

  const closeHeaderMenu = () => {
    setOpen(false);
  };

  if (!isClient) {
    return null;
  }

  const getButton = () => {
    // TODO: fix this when bottom nav bar is working
    // if media query matches, just render for desktop
    if (window && !window.matchMedia('(min-width: 640px)').matches) {
      return <LanguageSelect />;
    }
    return (
      <div className="flex gap-2 items-center justify-end">
        <LanguageSelect />
        <button
          className="h-10 w-30 rounded-3xl flex justify-end items-center text-white bg-copper appearance-none ml-2"
          onClick={handleMenuClick}
        >
          <div className="flex gap-1 font-bold items-center justify-end w-full ">
            <CgMenu />
          </div>
        </button>
      </div>
    );
  };

  return (
    <>
      <div
        className={classNames(
          'h-14 bg-copper flex items-center justify-center w-full',
          { 'fixed z-50': float }
        )}
      >
        <div
          className={classNames(
            'flex items-center justify-between max-w-7xl w-full flex-row-reverse pl-5 pr-4 rounded-b-xl',
            {
              'fixed z-50': float,
              'justify-center': hideMenu,
              'justify-between': !hideMenu,
            }
          )}
        >
          {!hideMenu && <>{getButton()}</>}
          <div className="flex items-center justify-start gap-4">
            <Link href="/">
              <Image
                src={'/horizontal-logo.svg'}
                height={60}
                width={100}
                alt="pin"
                className="text-[#4B4B4B]"
              />
            </Link>
            {showCitySelector && <CitySelector />}
          </div>

          <Transition
            appear
            show={open}
            enter="transition ease-in duration-150"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition ease-out duration-150"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
            className="absolute left-0 -bottom-20 w-full box-content"
          >
            <div
              className={classNames(
                'bg-white',
                'flex',
                'flex-col',
                'justify-center',
                'items-center',
                'rounded-xl',
                'absolute',
                '-top-16',
                'w-full',
                'max-w-xs',
                'overflow-hidden'
              )}
            >
              {user ? (
                <UserModal onClose={closeHeaderMenu} show={open} />
              ) : (
                <LoginModal onClose={closeHeaderMenu} show={open} />
              )}
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default Header;
